<template>
  <drawer-form
    ref="drawerForm"
    title="产品授权"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">



    <a-form-model-item label='产品' prop="productKey">
      <a-select v-model="model.productKey">
        <a-select-option value="">请选择产品</a-select-option>
        <a-select-option v-for="product in productList" :value="product.productKey">{{product.name}}</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='设备' prop="allDevice">
      <a-radio-group v-model="model.allDevice" >
        <a-radio-button :value="true">全部设备</a-radio-button>
        <a-radio-button :value="false">部分设备</a-radio-button>
      </a-radio-group>
    </a-form-model-item>



  </drawer-form>

</template>
<script>

  export default {
    data() {
      return {
        vendorId:null,
        productList:[],
        model: {
          productKey:"",
          allDevice:true
        },

        rules: {
          productKey: [
            {required: true, message: '必填项'},
          ],
          allDevice: [
            {required: true, message: '必填项'},
          ],
        },
        loading: false,



      }
    },
    created() {
      this.$get('product').then((r) => {
        let data = r.data
        this.productList = data
      })
    },
    methods: {
      show(vendorId){
        this.vendorId=vendorId
        this.model.vendorId = vendorId
        this.loading=false
        this.model.productKey=""
        this.model.allDevice=true
        this.$refs.drawerForm.show()
      },

      hide() {
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        this.loading=true

        this.$post('vendor/addVendorProduct',this.model).then(() => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })

      },
      add() {
        this.loading = true
        this.$post('menu', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      edit() {
        this.loading = true
        this.$put('menu', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      }

    }
  }
</script>
